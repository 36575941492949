/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: Verdana_Bold;
    src: url("/assets/fonts/Verdana Bold.ttf") format('truetype');
}

@font-face{
    font-family: Verdana;
    src: url("/assets/fonts/verdana.ttf") format('truetype');
}